@font-face {
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/ABCFavorit-Regular-v2.woff2") format("woff2");
  font-family: "ABCFavorit";
  font-display: swap;
}

@font-face {
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/ABCFavorit-RegularItalic-v2.woff2") format("woff2");
  font-family: "ABCFavorit";
  font-display: swap;
}
