/* Basic Elements */
body {
  margin-inline: auto;
  max-width: var(--container-max-width);
  overflow-x: clip;
  color: var(--color-text);
  font: var(--font-primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-primary);
}
