/* components/Footer.module.css */
.footer {
  padding-inline: var(--container-padding-inline);
  padding-block: 12px;
  font: var(--font-primary);
  font-size: var(--fluid-font-size-16px-18px);
}

.heading {
  font: var(--font-primary);
  font-size: var(--fluid-font-size-16px-18px);
}

.heading + * {
  margin-top: 6px;
}

.footerCols {
  display: flex;
  column-gap: 64px;
  row-gap: 20px;
  flex-wrap: wrap;
}

.footerAddress {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 200px;
}

.footerAddress:last-child {
  flex-grow: 1;
}

.footer a:is(:hover, :active, :focus) {
  color: var(--color-text-muted);
  text-decoration-line: var(--link-text-decoration-line);
  text-decoration-style: var(--link-text-decoration-style);
  text-decoration-color: var(--link-text-decoration-color);
  text-underline-offset: var(--link-text-underline-offset);
}

.footerNavList {
  display: flex;
  column-gap: 16px;
  row-gap: 8px;
  flex-wrap: wrap;
}

.email {
  word-break: keep-all;
}

.footerFoot {
  display: flex;
  column-gap: var(--grid-column-gap);
  row-gap: 12px;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
}

@media (min-width: 700px) {
  .footerFoot {
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 80px;
  }
}
