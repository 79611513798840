:root {
  /* Font Sizes */
  --font-size-12px: calc(12 / 16 * 1rem);
  --font-size-14px: calc(14 / 16 * 1rem);
  --font-size-16px: calc(16 / 16 * 1rem);
  --font-size-18px: calc(18 / 16 * 1rem);
  --font-size-20px: calc(20 / 16 * 1rem);
  --font-size-24px: calc(24 / 16 * 1rem);

  /* line-heights */
  --line-height-base: 1.3;

  /**
   * Fluid Text from 400px to 1024px
   * https://utopia.fyi/type/calculator?c=400,20,1,1024,24,1.25,0,0,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12
   */

  --fluid-font-size-16px-18px: clamp(1rem, 0.9199rem + 0.3205vi, 1.125rem); /* 16px → 18px */
  --fluid-font-size-base: clamp(1rem, 0.8397rem + 0.641vi, 1.25rem); /* 16px → 20px */
  --fluid-font-size-16px-24px: clamp(1rem, 0.6795rem + 1.2821vi, 1.5rem); /* 16px → 24px */
  --fluid-font-size-20px-24px: clamp(1.25rem, 1.0897rem + 0.641vi, 1.5rem); /* 20px → 24px */

  /* Breakpoints for reference */
  --breakpoint-sm: 36rem; /* 576px */
  --breakpoint-md: 48rem; /* 768px */
  --breakpoint-lg: 62rem; /* 992px */
  --breakpoint-xl: 75rem; /* 1200px */

  /* Colours */
  --color-black: #000;
  --color-white: #fff;
  --color-primary: #d5e99c;
  --color-grey: color-mix(in srgb, var(--color-black) 30%, transparent);

  /* Context Colours */
  --color-text: var(--color-black);
  --color-text-muted: var(--color-grey);

  /* Fonts */
  --font-family-primary: "ABCFavorit", sans-serif;
  --font-primary: 400 var(--fluid-font-size-base) / var(--line-height-base)
    var(--font-family-primary);

  /* Grid */
  --grid-column-gap: 16px; /* Space between columns */
  --grid-columns: 12; /* Number of columns in the grid */

  --container-max-width: 1728px;
  --container-padding-inline: 15px;

  /* Links */
  /**
   * Note: `text-decoration` shorthand is not supported in Safari.
   * So we can't make a simple rule like: `--link-text-decoration: underline var(--color-grey) dotted;`
   */
  --link-text-underline-offset: 4px;
  --link-text-decoration-style: dotted;
  --link-text-decoration-color: var(--color-grey);
  --link-text-decoration-line: underline;

  /* Decorative */
  --border-dotted: 1.5px dotted var(--color-grey);

  /* z-index */
  --z-index-header: 999;

  /* Header */
  --header-logo-height: 74px;
  --header-padding-block: var(--container-padding-inline); /* desktop */
  --header-height: calc(var(--header-logo-height) + (var(--header-padding-block) * 2));
}
